import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import AudioWrapper from './audio/AudioWrapper';
import './SpeechStage.css';

const SpeechStage = ({ 
  character, 
  text, 
  audioFile,
  questId,
  onNext 
}) => {
  const [key, setKey] = useState(0);
  const [visible, setVisible] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [audioProgress, setAudioProgress] = useState(0);

  useEffect(() => {
    setVisible(true);
    setAudioProgress(0);
    return () => {
      setVisible(false);
      setAudioProgress(0);
    };
  }, [text]);

  const handleAudioComplete = () => {
    setCanProceed(true);
    setAudioProgress(100);
  };

  const handleAudioStart = () => {
    setIsAudioPlaying(true);
  };

  const handleAudioPause = () => {
    setIsAudioPlaying(false);
  };

  const handleAudioProgress = (progress) => {
    setAudioProgress(progress);
  };

  const handleNext = () => {
    setVisible(false);
    setTimeout(() => {
      onNext();
      setKey((prevKey) => prevKey + 1);
      setAudioProgress(0);
    }, 300);
  };

  const renderText = () => {
    const textLength = text.length;
    const visibleLength = Math.floor((textLength * audioProgress) / 100);

    // Разбиваем текст на абзацы
    const paragraphs = text.split('\n').map(p => p.trim()).filter(p => p.length > 0);
    let currentLength = 0;

    return (
      <div className="text-container">
        {paragraphs.map((paragraph, pIndex) => {
          const paragraphStart = currentLength;
          currentLength += paragraph.length + 1; // +1 for newline

          // Определяем, сколько символов этого абзаца должно быть видимо
          const paragraphVisibleLength = Math.max(0, 
            Math.min(visibleLength - paragraphStart, paragraph.length)
          );

          const visiblePart = paragraph.slice(0, paragraphVisibleLength);
          const remainingPart = paragraph.slice(paragraphVisibleLength);

          return (
            <p key={pIndex} className="text-paragraph">
              {visiblePart.split('').map((char, index) => (
                <span 
                  key={`${pIndex}-${index}`}
                  className="burning-char"
                  style={{ 
                    '--char-index': paragraphStart + index,
                    animationDelay: '0ms',
                    opacity: 1,
                    animation: 'burnInEffect 1.5s ease-out forwards'
                  }}
                >
                  {char === ' ' ? '\u00A0' : char}
                </span>
              ))}
              {remainingPart.split('').map((char, index) => (
                <span
                  key={`${pIndex}-${paragraphVisibleLength + index}`}
                  className="burning-char"
                  style={{
                    opacity: 0
                  }}
                >
                  {char === ' ' ? '\u00A0' : char}
                </span>
              ))}
            </p>
          );
        })}
      </div>
    );
  };

  const audioUrl = audioFile ? `/audio/quests/${questId}/${audioFile}` : null;

  return (
    <div className="speech-stage-container">
      <h2 className="character-name">{character}</h2>

      <AudioWrapper 
        audioUrl={audioUrl}
        onComplete={handleAudioComplete}
        onPlay={handleAudioStart}
        onPause={handleAudioPause}
        onProgress={handleAudioProgress}
        className="audio-section"
      >
        <div 
          key={key} 
          className={`burning-text ${visible ? 'show' : ''} ${
            isAudioPlaying ? 'playing' : 'paused'
          }`}
        >
          {renderText()}
        </div>
      </AudioWrapper>

      <div className="controls-section">
        <Button 
          variant="contained"
          onClick={handleNext}
          disabled={!canProceed}
          className="next-button"
        >
          Next
        </Button>

        {!canProceed && audioUrl && (
          <div className="audio-notice">
            Please listen to the audio before proceeding
          </div>
        )}
      </div>
    </div>
  );
};

export default SpeechStage;
