import React, { useState } from 'react';
import { Button } from '@mui/material';
import AudioWrapper from './audio/AudioWrapper';
import './ImageStage.css';

const ImageStage = ({
  character,
  text,
  audioFile,
  questId,
  onFileChange,
  onSubmit,
  showHint,
  onHint,
  resultMessage,
  isProcessing,
  hint
}) => {
  const [hintText, setHintText] = useState('');
  const [fileName, setFileName] = useState('');
  const [canProceed, setCanProceed] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      onFileChange(event);
    }
  };

  const handleHintClick = () => {
    setHintText(hint);
    if (onHint) {
      onHint();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (canProceed) {
      onSubmit(e);
    }
  };

  const handleAudioComplete = () => {
    setCanProceed(true);
  };

  const handleAudioStart = () => {
    setIsAudioPlaying(true);
  };

  const handleAudioPause = () => {
    setIsAudioPlaying(false);
  };

  const audioUrl = audioFile ? `/audio/quests/${questId}/${audioFile}` : null;

  return (
    <div className="image-stage-container">
      <div className="character-section">
        <h2>{character}</h2>
      </div>

      {audioUrl && (
        <AudioWrapper
          audioUrl={audioUrl}
          onComplete={handleAudioComplete}
          onPlay={handleAudioStart}
          onPause={handleAudioPause}
          className="audio-section"
        >
          <div className={`task-animation ${isAudioPlaying ? 'playing' : ''}`}>
            <p className="task-text">{text}</p>
          </div>
        </AudioWrapper>
      )}

      <form onSubmit={handleSubmit} className="image-form">
        <div className="file-input-container">
          <label className="file-input-label">
            {fileName || 'Choose image...'}
            <input
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              disabled={isProcessing || !canProceed}
              className="file-input"
            />
          </label>
          {fileName && (
            <p className="file-name">
              Selected: {fileName}
            </p>
          )}
        </div>

        {fileName && (
          <Button 
            type="submit"
            variant="contained"
            disabled={isProcessing || !canProceed}
            className="submit-button"
          >
            {isProcessing ? 'Uploading...' : 'Submit'}
          </Button>
        )}
      </form>

      {showHint && (
        <div className="hint-section">
          <Button
            variant="outlined"
            onClick={handleHintClick}
            disabled={isProcessing || !canProceed}
            className="hint-button"
          >
            Get Hint (-10 points)
          </Button>
          {hintText && (
            <div className="hint-text">
              <p>{hintText}</p>
            </div>
          )}
        </div>
      )}

      {resultMessage && (
        <div 
          className={`result-message ${
            resultMessage.includes('matches') ? 'success' : 'error'
          }`}
        >
          {resultMessage}
        </div>
      )}

      {!canProceed && audioUrl && (
        <div className="audio-notice">
          Please listen to the audio before proceeding
        </div>
      )}
    </div>
  );
};

export default ImageStage;
