import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuestState } from './QuestStateProvider';
import SpeechStage from './SpeechStage';
import RiddleStage from './RiddleStage';
import ImageStage from './ImageStage';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';
import axios from 'axios';
import './QuestContent.css';

const QuestContent = ({ quest, token }) => {
  const navigate = useNavigate();
  const {
    questId,
    currentStep,
    currentSubStep,
    points,
    role,
    isLoading: stateLoading,
    error: stateError,
    updateState,
    applyHint,
    addIncorrectAttempt,
    isSyncing,
  } = useQuestState();

  const [inputText, setInputText] = useState('');
  const [image, setImage] = useState(null);
  const [resultMessage, setResultMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [localError, setLocalError] = useState(null);
  const progressionRef = useRef(false);

  useEffect(() => {
    const savedState = localStorage.getItem(`questState_${questId}`);
    if (savedState && !stateLoading) {
      try {
        const parsedState = JSON.parse(savedState);
        updateState(parsedState);
      } catch (error) {
        console.error('Error restoring state:', error);
      }
    }
  }, [questId, stateLoading, updateState]);

  const getCurrentStage = useCallback(() => {
    if (!quest?.points?.[currentStep - 1]?.steps?.[currentSubStep]) {
      return null;
    }

    const stage = quest.points[currentStep - 1].steps[currentSubStep];
    return stage;
  }, [quest, currentStep, currentSubStep]);

  const handleStepProgression = useCallback(async () => {
    if (!quest || progressionRef.current || isProcessing) {
      return;
    }

    try {
      progressionRef.current = true;
      const currentPoint = quest.points[currentStep - 1];
      
      if (!currentPoint) {
        throw new Error('Invalid current point');
      }

      let nextStep = currentStep;
      let nextSubStep = currentSubStep;

      if (currentSubStep + 1 >= currentPoint.steps.length) {
        if (currentStep >= quest.points.length) {
          await updateState({
            isCompleted: true,
            completedAt: new Date().toISOString()
          });
          navigate(`/quest/${questId}/complete`);
          return;
        }
        nextStep = currentStep + 1;
        nextSubStep = 0;
      } else {
        nextSubStep = currentSubStep + 1;
      }

      await updateState({
        currentStep: nextStep,
        currentSubStep: nextSubStep,
        completedSteps: nextStep > currentStep 
          ? [...(quest.completedSteps || []), currentStep]
          : quest.completedSteps || []
      });

      setTimeout(() => {
        setResultMessage('');
        navigate(
          `/quest/${questId}/step/${nextStep}/substep/${nextSubStep}`,
          { replace: true }
        );
      }, 1000);

    } catch (error) {
      console.error('Error in progression:', error);
      setLocalError('Failed to progress to next step');
    } finally {
      progressionRef.current = false;
    }
  }, [quest, currentStep, currentSubStep, questId, isProcessing, updateState, navigate]);

  const handleTextSubmit = async (event) => {
    event.preventDefault();
    
    if (isProcessing || !inputText.trim()) return;

    const currentStage = getCurrentStage();
    if (!currentStage?.correctAnswer) return;

    setIsProcessing(true);
    try {
      // Разделяем возможные правильные ответы и очищаем их от пробелов
      const correctAnswers = currentStage.correctAnswer.split('|').map(answer => answer.trim().toLowerCase());
      const userAnswer = inputText.toLowerCase().trim();

      // Проверяем, совпадает ли ответ пользователя с одним из возможных ответов
      const isCorrect = correctAnswers.some(answer => answer === userAnswer);

      if (isCorrect) {
        setResultMessage('Correct!');
        setInputText('');
        await new Promise(resolve => setTimeout(resolve, 1000));
        await handleStepProgression();
      } else {
        const result = await addIncorrectAttempt();
        setResultMessage(
          `Incorrect answer. Please try again! Points remaining: ${result?.points || points}`
        );
      }
    } catch (error) {
      console.error('Error processing answer:', error);
      setLocalError('Error checking answer');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleImageSubmit = async (event) => {
    event.preventDefault();
    
    if (isProcessing || !image) return;

    setIsProcessing(true);
    const formData = new FormData();
    formData.append('file', image);
    formData.append('quest_id', questId);
    formData.append('point_index', currentStep - 1);
    formData.append('step_index', currentSubStep);

    try {
      const response = await axios.post(
        'https://app.wend.co.il/api/upload-image/',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      const similarity = response.data.similarity || 0;
      const SIMILARITY_THRESHOLD = 25.0;

      if (similarity >= SIMILARITY_THRESHOLD) {
        setImage(null);
        setResultMessage('Image matches the template!');
        await new Promise(resolve => setTimeout(resolve, 1000));
        await handleStepProgression();
      } else {
        const result = await addIncorrectAttempt();
        setResultMessage(
          `Image doesn't match. Required similarity: ${SIMILARITY_THRESHOLD}%, got: ${similarity.toFixed(2)}%. ` +
          `Points: ${result?.points || points}`
        );
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setLocalError(error.response?.data?.message || 'Error uploading image');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleHint = async (hintId) => {
    if (!hintId) return;
    
    try {
      const result = await applyHint(hintId);
      if (result) {
        setResultMessage(`Hint applied. Points remaining: ${result.points}`);
      }
    } catch (error) {
      console.error('Error applying hint:', error);
      setLocalError('Error showing hint');
    }
  };

  useEffect(() => {
    setResultMessage('');
  }, [currentStep, currentSubStep]);

  if (stateLoading || isSyncing) {
    return <LoadingSpinner message="Loading quest state..." />;
  }

  if (stateError || localError) {
    return (
      <ErrorMessage
        message={stateError || localError}
        retryAction={() => {
          setLocalError(null);
          window.location.reload();
        }}
      />
    );
  }

  const currentStage = getCurrentStage();
  if (!currentStage) {
    return <ErrorMessage message="Invalid quest stage" />;
  }

  if ((role === 'Listener' && currentStage.type !== 'speech') ||
      (role === 'Gamer' && currentStage.type === 'speech')) {
    if (!progressionRef.current) {
      handleStepProgression();
    }
    return <LoadingSpinner message="Moving to next stage..." />;
  }

  const currentPoint = quest.points[currentStep - 1];

  const renderStageContent = () => {
    const commonProps = {
      questId,
      character: currentPoint.character,
      text: currentStage.text,
      audioFile: currentStage.audioFile
    };

    switch (currentStage.type) {
      case 'speech':
        return (
          <SpeechStage
            {...commonProps}
            onNext={handleStepProgression}
          />
        );

      case 'riddle':
        return (
          <RiddleStage
            {...commonProps}
            inputText={inputText}
            onInputChange={(e) => setInputText(e.target.value)}
            onSubmit={handleTextSubmit}
            showHint={!!currentStage.hint && points > 10}
            onHint={() => handleHint(currentStage.id)}
            resultMessage={resultMessage}
            isProcessing={isProcessing}
            hint={currentStage.hint}
          />
        );

      case 'image':
        return (
          <ImageStage
            {...commonProps}
            onFileChange={(e) => setImage(e.target.files[0])}
            onSubmit={handleImageSubmit}
            showHint={!!currentStage.hint && points > 10}
            onHint={() => handleHint(currentStage.id)}
            resultMessage={resultMessage}
            isProcessing={isProcessing}
            hint={currentStage.hint}
          />
        );

      default:
        console.warn('Unknown stage type:', currentStage.type);
        return null;
    }
  };

  return (
    <div className="quest-content">
      <div className="quest-header">
        <h1>{quest.name}</h1>
        <div className="quest-progress">
          <div className="progress-info">
            <span>Step {currentStep} of {quest.points.length}</span>
            <span>Points: {points}</span>
          </div>
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${(currentStep / quest.points.length) * 100}%` }}
            />
          </div>
        </div>
      </div>
      {renderStageContent()}
    </div>
  );
};

QuestContent.propTypes = {
  quest: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        character: PropTypes.string.isRequired,
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.oneOf(['speech', 'riddle', 'image']).isRequired,
            text: PropTypes.string.isRequired,
            correctAnswer: PropTypes.string,
            hint: PropTypes.string,
            audioFile: PropTypes.string,
            id: PropTypes.string,
          })
        ).isRequired,
      })
    ).isRequired,
  }),
  token: PropTypes.string.isRequired,
};

export default QuestContent;
