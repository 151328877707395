import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const useQuest = (questId, token) => {
  const [quest, setQuest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuest = async () => {
      try {
        setLoading(true);
        setError(null);

        // Сначала проверяем локальное хранилище
        const savedQuest = localStorage.getItem('currentQuest');
        const savedQuestData = savedQuest ? JSON.parse(savedQuest) : null;

        // Проверяем, соответствует ли сохранённый квест текущему ID
        if (savedQuestData && savedQuestData._id === questId) {
          setQuest(savedQuestData);
          setLoading(false);
          return;
        }

        // Если нет сохранённого квеста или ID не совпадает, делаем запрос
        const response = await axios.get(
          `https://app.wend.co.il/api/quests/${questId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const questData = response.data;
        setQuest(questData);
        
        // Сохраняем в localStorage
        localStorage.setItem('currentQuest', JSON.stringify(questData));

        // Получаем состояние квеста
        const stateResponse = await axios.get(
          `https://app.wend.co.il/api/quest-state/${questId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Сохраняем состояние в localStorage
        localStorage.setItem(`questState_${questId}`, JSON.stringify(stateResponse.data));

      } catch (error) {
        console.error('Error fetching quest:', error);
        setError(error.response?.data?.message || 'Failed to load quest data');
        
        if (error.response?.status === 404) {
          navigate('/');
        }
      } finally {
        setLoading(false);
      }
    };

    if (questId && token) {
      fetchQuest();
    }
  }, [questId, token, navigate]);

  const updateQuest = async (updates) => {
    try {
      setLoading(true);
      
      const updatedQuest = { ...quest, ...updates };
      setQuest(updatedQuest);
      
      localStorage.setItem('currentQuest', JSON.stringify(updatedQuest));
      
      // Опционально: отправка обновлений на сервер
      await axios.post(
        `https://app.wend.co.il/api/quests/${questId}`,
        updates,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error updating quest:', error);
      setError(error.response?.data?.message || 'Failed to update quest');
    } finally {
      setLoading(false);
    }
  };

  const clearQuest = () => {
    localStorage.removeItem('currentQuest');
    localStorage.removeItem(`questState_${questId}`);
    setQuest(null);
  };

  const resetQuestState = async () => {
    try {
      setLoading(true);
      
      // Очищаем локальное состояние
      localStorage.removeItem(`questState_${questId}`);
      
      // Сбрасываем состояние на сервере
      await axios.post(
        `https://app.wend.co.il/api/quest-state/${questId}/reset`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Перезагружаем квест
      const response = await axios.get(
        `https://app.wend.co.il/api/quests/${questId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setQuest(response.data);
      localStorage.setItem('currentQuest', JSON.stringify(response.data));
      
    } catch (error) {
      console.error('Error resetting quest state:', error);
      setError(error.response?.data?.message || 'Failed to reset quest state');
    } finally {
      setLoading(false);
    }
  };

  return {
    quest,
    loading,
    error,
    updateQuest,
    clearQuest,
    resetQuestState,
  };
};
