import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { sendMessage, addEventListener, removeEventListener } from '../socket';

const QuestStateContext = createContext(null);

export const useQuestState = () => {
  const context = useContext(QuestStateContext);
  if (!context) {
    throw new Error('useQuestState must be used within a QuestStateProvider');
  }
  return context;
};

export const QuestStateProvider = ({ children, initialState }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(() => ({
    questId: '',
    currentStep: 1,
    currentSubStep: 0,
    points: 100,
    role: '',
    incorrectAttempts: 0,
    usedHints: [],
    completedSteps: [],
    error: null,
    lastUpdated: new Date(),
    ...initialState,
  }));

  const [isSyncing, setIsSyncing] = useState(false);
  const lastUpdateRef = useRef(null);
  const updateTimeoutRef = useRef(null);
  const stateRef = useRef(state);

  // Обновляем ref при изменении state
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  // Синхронизация с localStorage
  const syncToStorage = useCallback((newState) => {
    try {
      localStorage.setItem(`questState_${newState.questId}`, JSON.stringify(newState));
    } catch (error) {
      console.error('Error syncing to storage:', error);
    }
  }, []);

  const updateState = useCallback(async (updates, skipSync = false) => {
    if (isSyncing) return;

    try {
      setIsSyncing(true);
      const timestamp = new Date();

      // Проверяем дубликаты
      if (lastUpdateRef.current &&
          JSON.stringify(lastUpdateRef.current.data) === JSON.stringify(updates) &&
          timestamp - lastUpdateRef.current.timestamp < 1000) {
        return;
      }

      const newState = {
        ...stateRef.current,
        ...updates,
        lastUpdated: timestamp,
      };

      setState(newState);
      lastUpdateRef.current = { data: updates, timestamp };

      if (!skipSync) {
        // Синхронизируем с localStorage
        syncToStorage(newState);

        // Синхронизируем с сервером
        if (updateTimeoutRef.current) {
          clearTimeout(updateTimeoutRef.current);
        }

        updateTimeoutRef.current = setTimeout(async () => {
          try {
            await axios.post(
              `https://app.wend.co.il/api/quest-state/${newState.questId}`,
              {
                current_step: newState.currentStep,
                current_substep: newState.currentSubStep,
                points: newState.points,
                role: newState.role,
                incorrect_attempts: newState.incorrectAttempts,
                used_hints: newState.usedHints,
                completed_steps: newState.completedSteps,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              }
            );

            await sendMessage({
              type: 'state_update',
              data: {
                ...updates,
                questId: newState.questId,
                timestamp: timestamp.toISOString(),
              },
            });
          } catch (error) {
            console.error('Error syncing state:', error);
          }
        }, 300);
      }
    } finally {
      setIsSyncing(false);
    }
  }, [isSyncing, syncToStorage]);

  // Обработчик неверных попыток
  const addIncorrectAttempt = useCallback(async () => {
    const currentState = stateRef.current;
    const newPoints = Math.max(0, currentState.points - 5);
    const newAttempts = (currentState.incorrectAttempts || 0) + 1;

    await updateState({
      points: newPoints,
      incorrectAttempts: newAttempts,
    });

    return { points: newPoints, attempts: newAttempts };
  }, [updateState]);

  // Обработчик подсказок
  const applyHint = useCallback(async (hintId) => {
    const currentState = stateRef.current;
    if (currentState.usedHints.includes(hintId)) {
      return;
    }

    const newPoints = Math.max(0, currentState.points - 10);
    const newHints = [...currentState.usedHints, hintId];

    await updateState({
      points: newPoints,
      usedHints: newHints,
    });

    return { points: newPoints, usedHints: newHints };
  }, [updateState]);

  // Обработчик WebSocket сообщений
  useEffect(() => {
    const handleStateUpdate = (data) => {
      if (!data || !data.timestamp || isSyncing) return;

      const currentState = stateRef.current;
      if (!currentState.lastUpdated || new Date(data.timestamp) > new Date(currentState.lastUpdated)) {
        updateState(data, true);
      }
    };

    addEventListener('state_update', handleStateUpdate);
    return () => removeEventListener('state_update', handleStateUpdate);
  }, [updateState, isSyncing]);

  // Восстанавливаем состояние при монтировании
  useEffect(() => {
    const savedState = localStorage.getItem(`questState_${initialState.questId}`);
    if (savedState) {
      try {
        const parsed = JSON.parse(savedState);
        setState(prev => ({
          ...prev,
          ...parsed,
        }));
      } catch (error) {
        console.error('Error restoring state:', error);
      }
    }
  }, [initialState.questId]);

  const value = {
    ...state,
    updateState,
    applyHint,
    addIncorrectAttempt,
    isSyncing,
  };

  return (
    <QuestStateContext.Provider value={value}>
      {children}
    </QuestStateContext.Provider>
  );
};

QuestStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.shape({
    questId: PropTypes.string,
    currentStep: PropTypes.number,
    currentSubStep: PropTypes.number,
    points: PropTypes.number,
    role: PropTypes.string,
    incorrectAttempts: PropTypes.number,
    usedHints: PropTypes.arrayOf(PropTypes.string),
    completedSteps: PropTypes.arrayOf(PropTypes.number),
  }),
};

QuestStateProvider.defaultProps = {
  initialState: {
    questId: '',
    currentStep: 1,
    currentSubStep: 0,
    points: 100,
    role: '',
    incorrectAttempts: 0,
    usedHints: [],
    completedSteps: [],
  },
};

export default QuestStateProvider;
