import React from 'react';
import './FinishScreen.css';
import { Container } from '@mui/material';

const FinishScreen = ({ onRestart, points }) => {
  const handleRestart = () => {
    onRestart();
  };

  const handleWatchYoutube = () => {
    window.location.href = 'https://www.youtube.com';
  };

  const handleTapHamster = () => {
    alert('You tapped the hamster!');
  };

  return (
    <Container
      className={`finish-screen ${
        points >= 80 ? 'high-score' : points >= 50 ? 'medium-score' : 'low-score'
      }`}
    >
      <h1>Congratulations! You've completed the quest!</h1>
      <p>Your score: {points}</p>
      <div className="options">
        <button className="option-button" onClick={handleRestart}>
          Start another quest
        </button>
        <button className="option-button" onClick={handleWatchYoutube}>
          Watch YouTube
        </button>
        <button className="option-button" onClick={handleTapHamster}>
          Tap the hamster
        </button>
      </div>
    </Container>
  );
};

export default FinishScreen;

