import log from 'loglevel';

log.setLevel('DEBUG');

let socket = null;
let listeners = {};
let reconnectAttempts = 0;
let connectionQueue = Promise.resolve();
let messageQueue = [];
const MAX_RECONNECT_ATTEMPTS = 5;
const CONNECTION_TIMEOUT = 10000;
const RETRY_DELAY = 1000;
const MAX_MESSAGE_QUEUE = 100;

const getWebSocketUrl = (teamId) => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  return `${protocol}//${window.location.host}/ws/${teamId}`;
};

const processMessageQueue = async () => {
  while (messageQueue.length > 0 && socket?.readyState === WebSocket.OPEN) {
    const message = messageQueue.shift();
    try {
      await sendMessageImmediate(message);
      log.debug('Queued message sent successfully:', message);
    } catch (error) {
      log.error('Error sending queued message:', error);
      if (messageQueue.length < MAX_MESSAGE_QUEUE) {
        messageQueue.push(message);
      }
    }
  }
};

const sendMessageImmediate = async (message) => {
  return new Promise((resolve, reject) => {
    try {
      const messageString = JSON.stringify(message);
      socket.send(messageString);
      log.debug('Message sent:', message);
      resolve();
    } catch (error) {
      log.error('Error sending message:', error);
      reject(error);
    }
  });
};

export const createWebSocket = (teamId) => {
  return new Promise((resolve, reject) => {
    connectionQueue = connectionQueue.then(async () => {
      try {
        if (socket && socket.readyState !== WebSocket.CLOSED) {
          log.debug('Closing existing WebSocket connection');
          socket.close();
          await new Promise(resolve => setTimeout(resolve, 500));
        }

        const wsUrl = getWebSocketUrl(teamId);
        log.debug(`Connecting to WebSocket: ${wsUrl}`);
        
        socket = new WebSocket(wsUrl);
        
        const connectionTimeout = setTimeout(() => {
          if (socket.readyState !== WebSocket.OPEN) {
            socket.close();
            reject(new Error('WebSocket connection timeout'));
          }
        }, CONNECTION_TIMEOUT);

        socket.onopen = () => {
          clearTimeout(connectionTimeout);
          log.info('WebSocket connection established');
          reconnectAttempts = 0;
          processMessageQueue();
          resolve(socket);
        };

        socket.onerror = (error) => {
          clearTimeout(connectionTimeout);
          log.error('WebSocket error:', error);
          reject(error);
        };

        socket.onclose = async (event) => {
          clearTimeout(connectionTimeout);
          log.info('WebSocket connection closed', event);
          
          if (!event.wasClean && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
            reconnectAttempts++;
            const delay = Math.min(RETRY_DELAY * Math.pow(2, reconnectAttempts), 30000);
            log.info(`Attempting to reconnect in ${delay}ms (${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS})...`);
            
            try {
              await new Promise(resolve => setTimeout(resolve, delay));
              const newSocket = await createWebSocket(teamId);
              resolve(newSocket);
            } catch (error) {
              log.error('Reconnection failed:', error);
              if (reconnectAttempts === MAX_RECONNECT_ATTEMPTS) {
                log.error('Max reconnection attempts reached');
                reject(error);
              }
            }
          }
        };

        socket.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            log.debug('Received message:', data);

            // Обработка heartbeat
            if (data.type === 'heartbeat') {
              sendMessage({
                type: 'heartbeat_ack',
                timestamp: new Date().toISOString()
              });
              return;
            }

            // Обработка обновления состояния
            if (data.type === 'state_update') {
              if (listeners['state_update']) {
                listeners['state_update'].forEach(callback => {
                  try {
                    callback(data.data);
                  } catch (error) {
                    log.error('Error in state update handler:', error);
                  }
                });
              }
              return;
            }

            // Обработка других типов сообщений
            if (listeners[data.type]) {
              listeners[data.type].forEach(callback => {
                try {
                  callback(data);
                } catch (error) {
                  log.error(`Error in message handler for type ${data.type}:`, error);
                }
              });
            }
          } catch (error) {
            log.error('Error processing message:', error);
          }
        };

      } catch (error) {
        log.error('Error in createWebSocket:', error);
        reject(error);
      }
    });

    return connectionQueue;
  });
};

export const sendMessage = async (message) => {
  if (!socket || socket.readyState !== WebSocket.OPEN) {
    if (messageQueue.length < MAX_MESSAGE_QUEUE) {
      messageQueue.push(message);
      log.debug('Message queued:', message);
      return;
    }
    throw new Error('Message queue is full');
  }

  try {
    await sendMessageImmediate(message);
  } catch (error) {
    if (messageQueue.length < MAX_MESSAGE_QUEUE) {
      messageQueue.push(message);
      log.debug('Message queued after failed send:', message);
    } else {
      throw error;
    }
  }
};

export const addEventListener = (event, callback) => {
  if (!listeners[event]) {
    listeners[event] = new Set();
  }
  listeners[event].add(callback);
  log.debug(`Added listener for event: ${event}`);
};

export const removeEventListener = (event, callback) => {
  if (listeners[event]) {
    listeners[event].delete(callback);
    if (listeners[event].size === 0) {
      delete listeners[event];
    }
    log.debug(`Removed listener for event: ${event}`);
  }
};

export const closeWebSocket = () => {
  if (socket) {
    log.debug('Closing WebSocket connection');
    socket.close();
    socket = null;
    listeners = {};
    reconnectAttempts = 0;
    messageQueue = [];
  }
};

export const getConnectionState = () => {
  if (!socket) return 'CLOSED';
  return ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED'][socket.readyState];
};

// Периодическая проверка и очистка очереди сообщений
setInterval(() => {
  if (messageQueue.length > 0) {
    processMessageQueue();
  }
}, 5000);

// Экспортируем константы для использования в других местах
export const WebSocketStates = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3
};
