import React, { useState, useEffect } from 'react';
import './ErrorMessage.css';

const ErrorMessage = ({ 
  message = 'An error occurred', 
  retryAction = null,
  critical = false,
  timeout = 0 // 0 means no auto-hide
}) => {
  const [visible, setVisible] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (timeout > 0 && !critical) {
      const id = setTimeout(() => {
        setVisible(false);
      }, timeout);
      setTimeoutId(id);

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
  }, [timeout, critical, timeoutId]);

  const handleDismiss = () => {
    if (!critical) {
      setVisible(false);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  };

  const handleRetry = () => {
    if (retryAction && typeof retryAction === 'function') {
      retryAction();
    }
  };

  if (!visible) return null;

  return (
    <div className={`error-message ${critical ? 'critical' : ''}`}>
      <div className="error-content">
        <div className="error-icon">
          {critical ? '⚠️' : '❌'}
        </div>
        
        <div className="error-text">
          <h3>{critical ? 'Critical Error' : 'Error'}</h3>
          <p>{message}</p>
        </div>

        <div className="error-actions">
          {retryAction && (
            <button 
              className="retry-button"
              onClick={handleRetry}
            >
              Try Again
            </button>
          )}
          
          {!critical && (
            <button 
              className="dismiss-button"
              onClick={handleDismiss}
            >
              Dismiss
            </button>
          )}
        </div>
      </div>

      {/* Progress bar for auto-dismiss */}
      {timeout > 0 && !critical && (
        <div 
          className="error-progress"
          style={{
            animationDuration: `${timeout}ms`
          }}
        />
      )}
    </div>
  );
};

export default ErrorMessage;
