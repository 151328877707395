import { create } from 'zustand';
import { Howl } from 'howler';

export const useAudioStore = create((set, get) => ({
  sound: null,
  isPlaying: false,
  progress: 0,
  duration: 0,
  isLoading: false,
  error: null,

  setError: (error) => {
    console.error('Audio error:', error);
    set({ error: error.toString() });
  },

  initSound: (url) => {
    try {
      const currentState = get();
      if (currentState.sound?.state() === 'loaded' && currentState.sound._src === url) {
        return;
      }

      set({ isLoading: true, error: null });

      if (currentState.sound) {
        currentState.sound.unload();
      }

      if (!url) {
        set({
          sound: null,
          isPlaying: false,
          progress: 0,
          duration: 0,
          isLoading: false
        });
        return;
      }

      if (!url.match(/\.(mp3|wav|ogg|m4a)$/i)) {
        throw new Error('Unsupported audio format');
      }

      const sound = new Howl({
        src: [url],
        html5: true,
        preload: true,
        format: ['mp3', 'wav', 'ogg', 'm4a'],
        onload: () => {
          set({
            duration: sound.duration(),
            isLoading: false
          });
          if (!get().isPlaying) {
            sound.play();
          }
        },
        onplay: () => {
          set({ isPlaying: true });
        },
        onend: () => {
          set({
            isPlaying: false,
            progress: 100
          });
        },
        onpause: () => {
          set({ isPlaying: false });
        },
        onstop: () => {
          set({
            isPlaying: false,
            progress: 0
          });
        },
        onseek: () => {
          const progress = (sound.seek() / sound.duration()) * 100;
          set({ progress });
        },
        onloaderror: (id, error) => {
          set({
            error: `Failed to load audio: ${error}`,
            isLoading: false
          });
        },
        onplayerror: (id, error) => {
          set({
            error: `Failed to play audio: ${error}`,
            isLoading: false
          });
        }
      });

      set({ sound });

    } catch (error) {
      console.error('Error initializing audio:', error);
      set({
        error: `Error initializing audio: ${error.message}`,
        isLoading: false
      });
    }
  },

  togglePlayPause: () => {
    const { sound, isPlaying } = get();
    if (sound) {
      if (isPlaying) {
        sound.pause();
      } else {
        sound.play();
      }
    }
  },

  skip: () => {
    const { sound } = get();
    if (sound) {
      sound.stop();
      set({ progress: 100 });
    }
  },

  updateProgress: () => {
    const { sound } = get();
    if (sound && sound.playing()) {
      try {
        const seek = sound.seek() || 0;
        const duration = sound.duration() || 1;
        const progress = Math.min((seek / duration) * 100, 100);
        
        if (progress !== get().progress) {
          set({ progress });
        }
        
        return progress;
      } catch (error) {
        console.error('Error updating progress:', error);
        set({ error: `Progress update error: ${error.message}` });
      }
    }
    return get().progress;
  },

  setVolume: (volume) => {
    const { sound } = get();
    if (sound) {
      sound.volume(Math.max(0, Math.min(1, volume)));
    }
  },

  mute: () => {
    const { sound } = get();
    if (sound) {
      sound.mute(!sound.muted());
    }
  },

  seek: (position) => {
    const { sound } = get();
    if (sound) {
      sound.seek(position);
    }
  },

  cleanup: () => {
    const { sound } = get();
    if (sound) {
      sound.unload();
    }
    set({
      sound: null,
      isPlaying: false,
      progress: 0,
      duration: 0,
      isLoading: false,
      error: null
    });
  }
}));

export default useAudioStore;
