import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './QuestPage.css';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';

const QuestPage = ({ token, onStart, isLoading: globalLoading }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [quest, setQuest] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamId, setTeamId] = useState('');
  const [isTeam, setIsTeam] = useState(false);
  const [participants, setParticipants] = useState(1);
  const [isStarting, setIsStarting] = useState(false);
  const [localLoading, setLocalLoading] = useState(true);

  // TEST ONLY: Функция сброса прогресса квеста - удалить перед продакшеном
  const handleResetQuest = async () => {
    try {
      setLocalLoading(true);
      setErrorMessage('');

      const response = await axios.post(
        `https://app.wend.co.il/api/quest-state/${id}/reset`,
        {},
        {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === 'success') {
        // Очищаем локальное состояние
        localStorage.removeItem(`questState_${id}`);
        localStorage.removeItem('currentQuest');
        localStorage.removeItem(`questMode_${id}`);
        localStorage.removeItem(`questPage_${id}`);
        
        setErrorMessage('Quest progress reset successfully');
        
        // Даем время увидеть сообщение об успехе
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setErrorMessage('Failed to reset quest progress');
      }
    } catch (error) {
      console.error('Error resetting quest:', error);
      setErrorMessage(
        error.response?.data?.detail || 
        error.message || 
        'Failed to reset quest progress'
      );
    } finally {
      setLocalLoading(false);
    }
  };

  useEffect(() => {
    const fetchQuestData = async () => {
      try {
        setLocalLoading(true);
        setErrorMessage('');

        const [questResponse, paymentResponse] = await Promise.all([
          axios.get(`https://app.wend.co.il/api/quests/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          token
            ? axios.get(
                `https://app.wend.co.il/api/account/quest-status/${id}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
            : Promise.resolve({ data: { status: 'not_purchased' } }),
        ]);

        setQuest(questResponse.data);
        setIsPaid(paymentResponse.data.status === 'purchased');
        localStorage.setItem('currentQuest', JSON.stringify(questResponse.data));

      } catch (error) {
        setErrorMessage(
          error.response?.data?.detail || 'Error fetching quest information.'
        );
      } finally {
        setLocalLoading(false);
      }
    };

    if (id && token) {
      fetchQuestData();
    }
  }, [id, token]);

  useEffect(() => {
    const savedState = localStorage.getItem(`questPage_${id}`);
    if (savedState) {
      try {
        const state = JSON.parse(savedState);
        setSelectedRole(state.selectedRole || '');
        setTeamName(state.teamName || '');
        setIsTeam(state.isTeam || false);
        setParticipants(state.participants || 1);
      } catch (error) {
        console.error('Error restoring quest page state:', error);
      }
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      localStorage.setItem(`questPage_${id}`, JSON.stringify({
        selectedRole,
        teamName,
        isTeam,
        participants,
      }));
    }
  }, [id, selectedRole, teamName, isTeam, participants]);

  const handlePayment = async () => {
    try {
      setErrorMessage('');
      setLocalLoading(true);

      const response = await axios.post(
        'https://app.wend.co.il/api/account/pay',
        {
          quest_id: id,
          amount: quest.price,
          payment_method: 'PayPal',
          participants: participants,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { redirect_url } = response.data;
      if (redirect_url) {
        localStorage.setItem(`questPage_${id}_payment`, JSON.stringify({
          selectedRole,
          teamName,
          isTeam,
          participants,
        }));
        window.location.href = redirect_url;
      } else {
        setErrorMessage('Invalid payment response from server');
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.detail ||
          'Error initiating payment. Please try again.'
      );
    } finally {
      setLocalLoading(false);
    }
  };

  const handleCreateTeam = async () => {
    try {
      setErrorMessage('');
      setLocalLoading(true);

      const response = await axios.post(
        'https://app.wend.co.il/api/team/create',
        {
          name: teamName,
          quest_id: id,
          participants: participants,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.team_id) {
        setTeamId(response.data.team_id);
        localStorage.setItem(`questTeam_${id}`, response.data.team_id);
      } else {
        setErrorMessage('Invalid team creation response from server');
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.detail || 'Error creating team. Please try again.'
      );
    } finally {
      setLocalLoading(false);
    }
  };

  const handleStartQuest = async () => {
    if (!selectedRole) {
      setErrorMessage('Please select a role before starting the quest.');
      return;
    }

    try {
      setIsStarting(true);
      setErrorMessage('');

      const questModeData = {
        isTeam: !!teamId,
        role: selectedRole
      };
      localStorage.setItem(`questMode_${id}`, JSON.stringify(questModeData));

      const nextRoute = await onStart(id, selectedRole, isTeam ? teamId : null);
      if (nextRoute) {
        localStorage.removeItem(`questPage_${id}`);
        localStorage.removeItem(`questPage_${id}_payment`);
        navigate(nextRoute);
      } else {
        throw new Error('No route returned from quest start');
      }
    } catch (error) {
      setErrorMessage(
        error.message || 'Failed to start quest. Please try again.'
      );
    } finally {
      setIsStarting(false);
    }
  };

  if (localLoading || globalLoading) {
    return (
      <div className="quest-page-container">
        <LoadingSpinner message="Loading quest information..." />
      </div>
    );
  }

  if (!quest) {
    return (
      <div className="quest-page-container">
        <ErrorMessage 
          message="Quest not found" 
          retryAction={() => navigate('/')} 
        />
      </div>
    );
  }

  return (
    <div className="quest-page-container">
      <h1 className="quest-title">{quest.name}</h1>
      <p className="quest-description">{quest.description}</p>

      <div className="quest-details">
        <p className="quest-info">
          <strong>Price:</strong> ${quest.price}
        </p>
        <p className="quest-info">
          <strong>Duration:</strong> {quest.duration} minutes
        </p>
        <p className="quest-info">
          <strong>Difficulty:</strong> {quest.difficulty}
        </p>
      </div>

      {token ? (
        isPaid ? (
          <div className="quest-start-section">
            <div className="role-selection">
              <select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                disabled={isStarting}
              >
                <option value="">Select a role</option>
                <option value="Gamer">Gamer</option>
                <option value="Listener">Listener</option>
                <option value="Regular">Regular</option>
              </select>
              <p className="role-description">
                {selectedRole === 'Gamer' &&
                  "As a Gamer, you'll be responsible for solving puzzles and riddles."}
                {selectedRole === 'Listener' &&
                  "As a Listener, you'll receive important story elements and clues."}
                {selectedRole === 'Regular' &&
                  'Experience the full quest with both puzzles and story elements.'}
              </p>
            </div>

            <div className="mode-selection">
              <label>
                Play Mode:
                <select
                  value={isTeam.toString()}
                  onChange={(e) => setIsTeam(e.target.value === 'true')}
                  disabled={isStarting}
                >
                  <option value="false">Solo</option>
                  <option value="true">Team</option>
                </select>
              </label>
            </div>

            {isTeam && (
              <div className="team-section">
                <label>
                  Team Name:
                  <input
                    type="text"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    disabled={isStarting || !!teamId}
                    placeholder="Enter team name"
                  />
                </label>

                <label>
                  Number of Participants:
                  <input
                    type="number"
                    min="2"
                    max="10"
                    value={participants}
                    onChange={(e) =>
                      setParticipants(Math.min(10, Math.max(2, parseInt(e.target.value) || 2)))
                    }
                    disabled={isStarting || !!teamId}
                  />
                </label>

                {!teamId && (
                  <button
                    className="create-team-button"
                    onClick={handleCreateTeam}
                    disabled={!teamName || isStarting}
                  >
                    Create Team
                  </button>
                )}
              </div>
            )}

            <button
              className="start-button"
              onClick={handleStartQuest}
              disabled={
                !selectedRole || 
                isStarting || 
                (isTeam && !teamId)
              }
            >
              {isStarting 
                ? 'Starting Quest...' 
                : `Start ${isTeam ? 'Team' : 'Solo'} Quest`
              }
            </button>

            {/* TEST ONLY: Кнопка сброса прогресса - удалить перед продакшеном */}
            <button
              className="reset-button"
              onClick={handleResetQuest}
              disabled={isStarting || localLoading}
              style={{
                marginTop: '20px',
                backgroundColor: '#ff4444',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer'
              }}
            >
              Reset Quest Progress (Test Only)
            </button>
          </div>
        ) : (
          <div className="quest-purchase-section">
            <label>
              Type of Participation:
              <select
                value={isTeam.toString()}
                onChange={(e) => setIsTeam(e.target.value === 'true')}
                className="participation-select"
              >
                <option value="false">Single Player</option>
                <option value="true">Team</option>
              </select>
            </label>

            {isTeam && (
              <label>
                Number of Participants:
                <input
                  type="number"
                  min="2"
                  max="10"
                  value={participants}
                  onChange={(e) =>
                    setParticipants(
                      Math.min(10, Math.max(2, parseInt(e.target.value) || 2))
                    )
                  }
                  className="participants-input"
                />
              </label>
            )}

            <button
              className="buy-button"
              onClick={handlePayment}
              disabled={isStarting || localLoading}
            >
              {localLoading 
                ? 'Processing...' 
                : `Pay $${(quest.price * (isTeam ? participants : 1)).toFixed(2)}`
              }
            </button>
          </div>
        )
      ) : (
        <div className="auth-reminder">
          <p>
            Please <a href="/login">login</a> or{' '}
            <a href="/register">register</a> to purchase this quest.
          </p>
        </div>
      )}

      {errorMessage && (
        <ErrorMessage 
          message={errorMessage} 
          timeout={5000}
          retryAction={() => setErrorMessage('')}
        />
      )}

      <button
        className="back-button"
        onClick={() => {
          localStorage.removeItem(`questPage_${id}`);
          navigate('/');
        }}
        disabled={isStarting || localLoading}
      >
        Back to Quests
      </button>
    </div>
  );
};

QuestPage.propTypes = {
  token: PropTypes.string.isRequired,
  onStart: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

QuestPage.defaultProps = {
  isLoading: false,
};

export default QuestPage;
