import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const executePayment = async () => {
      const urlParams = new URLSearchParams(location.search);
      const paymentId = urlParams.get('paymentId');
      const PayerID = urlParams.get('PayerID');
      const questId = urlParams.get('questId');

      if (!paymentId || !PayerID || !questId) {
        console.error('Missing paymentId, PayerID, or questId in URL');
        navigate('/payment-cancel');
        return;
      }

      try {
        const response = await axios.post('https://app.wend.co.il/api/account/complete-payment', {
          paymentId,
          PayerID,
          quest_id: questId
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Payment executed successfully:', response.data);
        navigate(`/quest/${questId}`);
      } catch (error) {
        if (error.response) {
          console.error('Payment execution error:', error.response.data);
        } else {
          console.error('Payment execution error:', error.message);
        }
        navigate('/payment-cancel');
      }
    };

    executePayment();
  }, [navigate, location.search, token]);

  return (
    <div>
      <h1>Payment Successful</h1>
      <p>Redirecting to the quest...</p>
    </div>
  );
};

export default PaymentSuccess;

