import React, { useEffect, useRef } from 'react';
import { useAudioStore } from './AudioController';
import { Button } from '@mui/material';
import { Pause, Play } from 'lucide-react';
import './styles.css';

const AudioWrapper = ({
  audioUrl,
  children,
  onComplete,
  onPlay,
  onPause,
  onProgress,
  className = '',
  showControls = true
}) => {
  const initSound = useAudioStore((state) => state.initSound);
  const setError = useAudioStore((state) => state.setError);
  const isLoading = useAudioStore((state) => state.isLoading);
  const isPlaying = useAudioStore((state) => state.isPlaying);
  const progress = useAudioStore((state) => state.progress);
  const error = useAudioStore((state) => state.error);
  const sound = useAudioStore((state) => state.sound);
  const cleanup = useAudioStore((state) => state.cleanup);
  const togglePlayPause = useAudioStore((state) => state.togglePlayPause);
  const updateProgress = useAudioStore((state) => state.updateProgress);

  const progressInterval = useRef(null);
  const loadingTimeout = useRef(null);
  const retryCount = useRef(0);
  const isInitialized = useRef(false);
  const MAX_RETRIES = 3;

  useEffect(() => {
    if (audioUrl && !isInitialized.current) {
      const checkAudio = async () => {
        try {
          const response = await fetch(audioUrl, { method: 'GET' });

          if (!response.ok) {
            throw new Error(`Audio file not available: ${response.status}`);
          }

          const contentType = response.headers.get('content-type');
          if (!contentType?.includes('audio/')) {
            console.warn('Unexpected content type:', contentType);
          }

          initSound(audioUrl);
          isInitialized.current = true;

          loadingTimeout.current = setTimeout(() => {
            if (isLoading) {
              setError('Audio loading timeout');
            }
          }, 30000);

        } catch (error) {
          console.error('Audio file check error:', error);

          if (retryCount.current < MAX_RETRIES) {
            retryCount.current++;
            setTimeout(checkAudio, 2000 * retryCount.current);
          } else {
            setError(`Failed to load audio after ${MAX_RETRIES} attempts`);
          }
        }
      };

      checkAudio();
    }

    return () => {
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
    };
  }, [audioUrl, initSound, isLoading, setError]);

  useEffect(() => {
    if (sound && !progressInterval.current) {
      progressInterval.current = setInterval(() => {
        const currentProgress = updateProgress();
        if (onProgress) {
          onProgress(currentProgress);
        }
      }, 50);
    }

    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
        progressInterval.current = null;
      }
    };
  }, [sound, updateProgress, onProgress]);

  useEffect(() => {
    return () => {
      isInitialized.current = false;
      cleanup();
    };
  }, [cleanup]);

  useEffect(() => {
    if (progress >= 100 && onComplete) {
      onComplete();
    }
  }, [progress, onComplete]);

  useEffect(() => {
    if (isPlaying && onPlay) {
      onPlay();
    }
    if (!isPlaying && onPause) {
      onPause();
    }
  }, [isPlaying, onPlay, onPause]);

  return (
    <div className={`audio-wrapper ${className}`}>
      <div className={`audio-content ${isPlaying ? 'playing' : ''}`}>
        {children}
      </div>
      {error && <div className="audio-error">{error}</div>}
      {showControls && (
        <div className="audio-controls">
          <Button 
            className="audio-control-button"
            onClick={togglePlayPause}
            disabled={!sound || isLoading}
          >
            {isPlaying ? <Pause size={20} /> : <Play size={20} />}
          </Button>
          <div className="progress-bar">
            <div 
              className="progress-fill" 
              style={{ width: `${progress}%` }} 
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioWrapper;
