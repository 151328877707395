import React, { useEffect, useState, useCallback } from "react";
import "./App.css";
import StartScreen from "./components/StartScreen";
import FinishScreen from "./components/FinishScreen";
import Account from "./components/Account";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentCancel from "./components/PaymentCancel";
import QuestPage from "./components/QuestPage";
import QuestContent from "./components/QuestContent";
import Login from "./components/Login";
import Register from "./components/Register";
import LoadingSpinner from "./components/LoadingSpinner";
import ErrorMessage from "./components/ErrorMessage";
import { useQuest } from "./hooks/useQuest"; // New import
import axios from "axios";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";
import { Container } from "@mui/material";
import { QuestStateProvider } from "./components/QuestStateProvider";
import { createWebSocket, sendMessage, closeWebSocket } from "./socket";

const QuestRoute = ({ initialQuest, isLoading, token }) => {
  const { id: questId } = useParams();
  const navigate = useNavigate();
  const [localError, setLocalError] = useState(null);
  const [questState, setQuestState] = useState(null);
  const [loadingState, setLoadingState] = useState(true);

  // Use the new useQuest hook
  const { quest, loading: questLoading, error: questError } = useQuest(questId, token);

  // Get saved quest data from localStorage
  const savedQuestData = localStorage.getItem(`quest_${questId}`);
  const { isTeam, role } = savedQuestData
    ? JSON.parse(savedQuestData)
    : { isTeam: false, role: "solo" };

  useEffect(() => {
    const fetchQuestState = async () => {
      try {
        const response = await axios.get(
          `https://app.wend.co.il/api/quest-state/${questId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const stateData = response.data;
        setQuestState({
          questId,
          currentStep: stateData.currentStep || 1,
          currentSubStep: stateData.currentSubStep || 0,
          points: stateData.points || 100,
          role: stateData.role || role,
          isTeam: isTeam,
          incorrectAttempts: stateData.incorrectAttempts || 0,
          usedHints: stateData.usedHints || [],
          completedSteps: stateData.completedSteps || [],
          error: null,
        });
        setLoadingState(false);

        // Update route to reflect current state
        navigate(
          `/quest/${questId}/step/${stateData.currentStep}/substep/${stateData.currentSubStep}`,
          { replace: true }
        );
      } catch (error) {
        console.error("Error fetching quest state:", error);
        setLocalError("Failed to load quest state");
        setLoadingState(false);
      }
    };

    if (quest) {
      fetchQuestState();
    }
  }, [questId, token, quest, isTeam, role, navigate]);

  if (isLoading || loadingState || questLoading) {
    return <LoadingSpinner message="Loading quest..." />;
  }

  if (localError || questError) {
    return <ErrorMessage message={localError || questError.message} />;
  }

  if (!quest) {
    return <ErrorMessage message="Quest not found" />;
  }

  if (!questState) {
    return <LoadingSpinner message="Initializing quest..." />;
  }

  console.log("Initializing quest with state:", questState);

  return (
    <QuestStateProvider initialState={questState}>
      <QuestContent quest={quest} token={token} onError={setLocalError} />
    </QuestStateProvider>
  );
};

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [quest, setQuest] = useState(() => {
    const saved = localStorage.getItem('currentQuest');
    return saved ? JSON.parse(saved) : null;
  });

  useEffect(() => {
    if (quest) {
      localStorage.setItem('currentQuest', JSON.stringify(quest));
    }
  }, [quest]);

  const api = axios.create();
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const newToken = await refreshToken();
        if (newToken) {
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return api(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );

  const saveToken = (userToken) => {
    localStorage.setItem("token", userToken);
    setToken(userToken);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("currentQuest");
    localStorage.removeItem("questState");
    setToken(null);
    closeWebSocket();
    setQuest(null);
  };

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await axios.post(
        "https://app.wend.co.il/api/account/refresh-token",
        {
          refresh_token: refreshToken,
        }
      );
      const newToken = response.data.access_token;
      saveToken(newToken);
      return newToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      logout();
      return null;
    }
  };

  const handleStartQuest = useCallback(
    async (questId, selectedRole, teamId = null) => {
      try {
        setIsLoading(true);
        setError(null);

        // Save quest mode data
        const questData = {
          isTeam: !!teamId,
          role: selectedRole,
        };
        localStorage.setItem(`quest_${questId}`, JSON.stringify(questData));

        // Fetch quest data
        const response = await api.get(
          `https://app.wend.co.il/api/quests/${questId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Save quest data
        setQuest(response.data);
        localStorage.setItem('currentQuest', JSON.stringify(response.data));

        // Initialize WebSocket
        await createWebSocket(questId);

        // Send start message
        await sendMessage({
          type: "start_quest",
          data: {
            questId,
            role: selectedRole,
            teamId,
            timestamp: new Date().toISOString(),
          },
        });

        setIsLoading(false);
        return `/quest/${questId}`; // Return route
      } catch (error) {
        console.error("Error starting quest:", error);
        setError(error.message || "Failed to start quest");
        setIsLoading(false);
        throw error;
      }
    },
    [token, api]
  );

  const ProtectedRoute = ({ children }) => {
    return token ? children : <Navigate to="/login" />;
  };

  const handleError = useCallback((error) => {
    console.error("Application error:", error);
    setError(
      error.response?.data?.message ||
      error.message ||
      "An unexpected error occurred"
    );
    setIsLoading(false);
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      closeWebSocket();
    };
  }, []);

  return (
    <Router>
      <Container className="App">
        {error && (
          <ErrorMessage
            message={error}
            retryAction={clearError}
            timeout={5000}
          />
        )}

        <Routes>
          <Route path="/" element={<StartScreen token={token} />} />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Account token={token} logout={logout} />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login setToken={saveToken} />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/quest/:id/start"
            element={
              <ProtectedRoute>
                <QuestPage
                  token={token}
                  onStart={handleStartQuest}
                  isLoading={isLoading}
                />
              </ProtectedRoute>
            }
          />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-cancel" element={<PaymentCancel />} />
          <Route
            path="/quest/:id/*"
            element={
              <ProtectedRoute>
                <React.Suspense
                  fallback={<LoadingSpinner message="Loading quest content..." />}
                >
                  <QuestRoute
                    initialQuest={quest}
                    isLoading={isLoading}
                    token={token}
                  />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/quest/:id/complete"
            element={
              <ProtectedRoute>
                <QuestStateProvider>
                  <FinishScreen
                    quest={quest}
                    onRestart={() => {
                      setQuest(null);
                      localStorage.removeItem('currentQuest');
                    }}
                  />
                </QuestStateProvider>
              </ProtectedRoute>
            }
          />
          <Route path="/quest" element={<Navigate to="/" replace />} />
          <Route
            path="*"
            element={
              <div className="not-found">
                <h2>Page Not Found</h2>
                <p>The page you're looking for doesn't exist.</p>
                <button onClick={() => window.history.back()}>Go Back</button>
              </div>
            }
          />
        </Routes>

        {isLoading && (
          <div className="global-loading">
            <LoadingSpinner message="Loading..." />
          </div>
        )}
      </Container>
    </Router>
  );
};

export default App;
