import React, { useState } from 'react';
import { Button } from '@mui/material';
import AudioWrapper from './audio/AudioWrapper';
import './RiddleStage.css';

const RiddleStage = ({
  character,
  text,
  audioFile,
  questId,
  inputText,
  onInputChange,
  onSubmit,
  showHint,
  onHint,
  resultMessage,
  isProcessing,
  hint
}) => {
  const [hintText, setHintText] = useState('');
  const [canProceed, setCanProceed] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const handleHintClick = () => {
    setHintText(hint);
    if (onHint) {
      onHint();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (canProceed && inputText.trim()) {
      onSubmit(e);
    }
  };

  const handleAudioComplete = () => {
    setCanProceed(true);
  };

  const handleAudioStart = () => {
    setIsAudioPlaying(true);
  };

  const handleAudioPause = () => {
    setIsAudioPlaying(false);
  };

  const audioUrl = audioFile ? `/audio/quests/${questId}/${audioFile}` : null;

  return (
    <div className="riddle-stage-container">
      <div className="character-section">
        <h2>{character}</h2>
      </div>

      {audioUrl && (
        <AudioWrapper
          audioUrl={audioUrl}
          onComplete={handleAudioComplete}
          onPlay={handleAudioStart}
          onPause={handleAudioPause}
          className="audio-section"
        >
          <div className={`riddle-animation ${isAudioPlaying ? 'playing' : ''}`}>
            <p className="riddle-text">{text}</p>
          </div>
        </AudioWrapper>
      )}

      <form onSubmit={handleSubmit} className="riddle-form">
        <input
          type="text"
          value={inputText}
          onChange={onInputChange}
          placeholder="Enter your answer"
          disabled={!canProceed}
          className="riddle-input"
        />
        
        <Button 
          type="submit"
          variant="contained"
          disabled={isProcessing || !canProceed || !inputText.trim()}
          className="submit-button"
        >
          {isProcessing ? 'Checking...' : 'Submit'}
        </Button>
      </form>

      {showHint && (
        <div className="hint-section">
          <Button
            variant="outlined"
            onClick={handleHintClick}
            disabled={isProcessing || !canProceed}
            className="hint-button"
          >
            Get Hint (-10 points)
          </Button>
          {hintText && (
            <div className="hint-text">
              <p>{hintText}</p>
            </div>
          )}
        </div>
      )}

      {resultMessage && (
        <div 
          className={`result-message ${
            resultMessage.includes('Correct') ? 'success' : 'error'
          }`}
        >
          {resultMessage}
        </div>
      )}

      {!canProceed && audioUrl && (
        <div className="audio-notice">
          Please listen to the audio before proceeding
        </div>
      )}
    </div>
  );
};

export default RiddleStage;
