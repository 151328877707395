import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      console.log('Регистрация начата');
      console.log('Данные для регистрации:', { email, username, password });
      const response = await axios.post('https://app.wend.co.il/api/account/register', {
        email,
        username,
        password,
      });
      console.log('Ответ сервера при регистрации:', response.data);
      alert('Registration successful! Please log in.');
      navigate('/login');
    } catch (error) {
      console.error('Ошибка при регистрации:', error);
      if (error.response) {
        console.error('Ответ сервера при ошибке регистрации:', error.response.data);
        setErrorMessage(error.response.data.detail || 'Error registering');
      } else {
        setErrorMessage('Error registering');
      }
    }
  };

  return (
    <div className="register-container">
      <h1>Register</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form onSubmit={handleRegister}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;

