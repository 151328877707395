import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Account.css';

const Account = ({ token, logout }) => {
  const [userData, setUserData] = useState({});
  const [questHistory, setQuestHistory] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [purchasedQuests, setPurchasedQuests] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user profile data
        const profileResponse = await axios.get('https://app.wend.co.il/api/account/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(profileResponse.data);
        localStorage.setItem('userData', JSON.stringify(profileResponse.data));

        // Fetch quest history
        const questHistoryResponse = await axios.get('https://app.wend.co.il/api/account/quest-history', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setQuestHistory(questHistoryResponse.data);
        localStorage.setItem('questHistory', JSON.stringify(questHistoryResponse.data));

        // Fetch transaction history
        const transactionHistoryResponse = await axios.get(
          'https://app.wend.co.il/api/account/transaction-history',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTransactionHistory(transactionHistoryResponse.data);
        localStorage.setItem('transactionHistory', JSON.stringify(transactionHistoryResponse.data));

        // Fetch purchased quests
        const purchasedQuestsResponse = await axios.get('https://app.wend.co.il/api/account/purchased-quests', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPurchasedQuests(purchasedQuestsResponse.data);
        localStorage.setItem('purchasedQuests', JSON.stringify(purchasedQuestsResponse.data));
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          // Token is invalid or expired, redirect to login
          logout();
          navigate('/login');
        } else {
          setError('Error fetching data: ' + error.message);
        }
      }
    };

    fetchData();
  }, [token, logout, navigate]);

  return (
    <div className="account-container">
      <h1>Account</h1>
      <button className="logout-button" onClick={logout}>
        Logout
      </button>
      <div className="profile-info">
        <h2>Profile</h2>
        <p>Name: {userData.username}</p>
        <p>Email: {userData.email}</p>
        {userData.avatar && <img src={userData.avatar} alt="User Avatar" className="avatar" />}
      </div>
      <div className="history-section">
        <h2>Quest History</h2>
        {questHistory.length ? (
          <ul>
            {questHistory.map((quest, index) => (
              <li key={index}>
                {quest.quest_name} - {quest.points} points - {new Date(quest.date).toLocaleString()}
              </li>
            ))}
          </ul>
        ) : (
          <p>No quest history available.</p>
        )}
      </div>
      <div className="history-section">
        <h2>Transaction History</h2>
        {transactionHistory.length ? (
          <ul>
            {transactionHistory.map((transaction, index) => (
              <li key={index}>
                {transaction.transaction_id} - ${transaction.amount} - {transaction.status} -{' '}
                {new Date(transaction.date).toLocaleString()}
              </li>
            ))}
          </ul>
        ) : (
          <p>No transaction history available.</p>
        )}
      </div>
      <div className="history-section">
        <h2>Purchased Quests</h2>
        {purchasedQuests.length ? (
          <ul>
            {purchasedQuests.map((quest, index) => (
              <li key={index}>
                {quest.quest_name} - Purchased at {new Date(quest.purchased_at).toLocaleString()}
              </li>
            ))}
          </ul>
        ) : (
          <p>No purchased quests available.</p>
        )}
      </div>
      {error && <p className="error-message">{error}</p>}
      <Link to="/" className="back-link">
        Back to Home
      </Link>
    </div>
  );
};

export default Account;

